<template>
  <div class="short_video">
    <div class="back" @click="$router.go(-1)">
      <svg-icon icon-class="tip_back" />
    </div>
    <v-touch
      v-show="videoList.length && !this.isFirst"
      @swipeleft="swipeLeft"
      @swiperight="swipeRight"
      :swipe-options="{ direction: 'horizontal' }"
      class="touch"
    >
      <transition name="slide-r">
        <div class="video-s">
          <Swiper class="video-swiper" :options="swiperOption" ref="mySwiper">
            <SwiperSlide class="my_swiper_silde" v-for="(item, index) in videoList" :key="item.id + index">
              <Video
                v-if="index > currentIndex - 2 && index < currentIndex + 2"
                :videoShow="currentIndex == index"
                :videoInfo="item"
                :index="index"
              />
              <div v-else></div>
            </SwiperSlide>
          </Swiper>
        </div>
      </transition>
    </v-touch>
    <!--    loading-->
    <Loading v-if="!videoList.length || this.isFirst" />
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { queryCollection } from '@/api/video';
import { queryCollecList, queryBuyList } from '@/api/mine';
import Video from '@/views/shortVideo/video.vue';
import Loading from '@/components/Loading';
import { getBeijinTime } from '@/utils';
import { queryCommunityList } from '@/api/community';
import { search } from '@/api/community';
import { getVidInfo } from '@/api/video';
import { getSessionItem } from '@/utils/longStorage';
import { Toast } from 'vant';
import { queryTagList } from '@/api/community';
export default {
  name: 'communityVideo',
  components: {
    Video,
    Swiper,
    SwiperSlide,
    Loading,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },

  data() {
    return {
      pageNumber: 1, //页码
      videoSize: 10, //一页短视频的条数
      videoList: [],
      currentIndex: 0, // 当前视频索引
      isFirst: true,
      observer: true, //修改swiper自己或子元素时，自动初始化swiper
      observeParents: true, //修改swiper的父元素时，自动初始化swiper
      swiperOption: {
        direction: 'vertical',
        on: {
          slideChangeTransitionEnd: () => {
            let activeIndex = this.swiper.activeIndex;
            this.currentIndex = activeIndex;
            // //滑到倒数第二条数据加载下一页视频数据
            if (this.currentIndex >= this.videoList.length - 2 && !this.isFirst) {
              this.pageNumber++;
              this.getVideo();
            }
          },
        },
      },
    };
  },
  mounted() {
    this.pageNumber = this.$route.query.page || 1;
    this.$store.dispatch('user/getWallet');
    this.getVideo();
  },
  methods: {
    swipeLeft() {
      let uid = this.videoList[this.currentIndex]?.publisher?.uid;
      this.$router.push({
        path: '/userHomePage',
        query: {
          uid: uid,
        },
      });
    },
    swipeRight() {
      this.$router.go(-1);
    },
    //获取社区视频
    async getCommunityVideo() {
      let activeName = this.$route.query.activeName;
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.videoSize,
        tag: activeName,
        reqDate: getBeijinTime(),
      };
      let ret = await this.$Api(queryCommunityList, req);
      if (ret && ret.code === 200) {
        if (ret.data.list !== null) {
          let videoList = ret.data.list.filter((video) => video.newsType == 'SP');
          this.videoList = this.videoList.concat(videoList);
          let vid = this.$route.query.vid;
          //第一次加载
          if (this.isFirst) {
            let fIndex = this.videoList.findIndex((e) => e.id == vid);
            if (fIndex == -1) {
              this.getVidList();
              return;
            }
            this.isFirst = false;
            this.swiper.slideTo(fIndex, 0, true);
          }
        }
      } else {
        Toast(ret.msg);
      }
    },
    //获取搜索视频
    async getSearchVideo() {
      let keyword = this.$route.query.keyword;
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.videoSize,
        keyword: keyword,
        videoType: 'SP',
      };
      let ret = await this.$Api(search, req);
      if (ret && ret.code === 200) {
        if (ret.data.list !== null) {
          let videoList = ret.data.list.filter((video) => video.newsType == 'SP');
          this.videoList = this.videoList.concat(videoList);
          let vid = this.$route.query.vid;
          //第一次加载
          if (this.isFirst) {
            let fIndex = this.videoList.findIndex((e) => e.id == vid);
            if (fIndex == -1) {
              this.getVidList();
              return;
            }
            this.isFirst = false;
            this.swiper.slideTo(fIndex, 0, true);
          }
        }
      } else {
        Toast(ret.msg);
      }
    },
    //获取推荐视频
    async getVidList() {
      let req = {
        videoID: this.$route.query.vid,
      };
      let ret = await this.$Api(getVidInfo, req);
      if (ret && ret.code === 200) {
        if (ret.data !== null) {
          this.videoList.push(ret.data);
          this.isFirst = false;
        } else {
          Toast('该视频已下架');
        }
      }
    },
    //获取收藏视频
    async getCollecVideo() {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.videoSize,
        type: 'video', // 影视-movie 图文-img 视频-video 话题-tag
        uid: JSON.parse(getSessionItem('userInfo')).uid,
      };
      let ret = await this.$Api(queryCollecList, req);
      if (ret && ret.code === 200) {
        if (ret.data.list !== null) {
          let videoList = ret.data.list.filter((video) => video.newsType == 'SP');
          this.videoList = this.videoList.concat(videoList);
          let vid = this.$route.query.vid;
          //第一次加载
          if (this.isFirst) {
            let fIndex = this.videoList.findIndex((e) => e.id == vid);
            if (fIndex == -1) {
              this.getVidList();
              return;
            }
            this.isFirst = false;
            this.swiper.slideTo(fIndex, 0, true);
          }
        }
      } else {
        Toast(ret.msg);
      }
    },
    //我的购买
    async getPurchasesVideo() {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.videoSize,
        newsType: 'SP', // 影视-MOVIE 图文-img 视频-SP 话题-tag
      };
      let ret = await this.$Api(queryBuyList, req);
      if (ret && ret.code === 200) {
        if (ret.data.list !== null) {
          let videoList = ret.data.list.filter((video) => video.newsType == 'SP');
          this.videoList = this.videoList.concat(videoList);
          let vid = this.$route.query.vid;
          //第一次加载
          if (this.isFirst) {
            let fIndex = this.videoList.findIndex((e) => e.id == vid);
            if (fIndex == -1) {
              this.getVidList();
              return;
            }
            this.isFirst = false;
            this.swiper.slideTo(fIndex, 0, true);
          }
        }
      } else {
        Toast(ret.msg);
      }
    },
    //个人主页
    async getUserVideo() {
      let uid = this.$route.query.uid;
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.videoSize,
        newsType: 'SP', // 影视-MOVIE 图文-img 视频-SP 话题-tag
        type: 'new', // 影视-MOVIE 图文-img 视频-SP 话题-tag
        uid: uid,
      };
      let ret = await this.$Api(queryCollection, req);
      if (ret && ret.code === 200) {
        if (ret.data.list !== null) {
          let videoList = ret.data.list;
          this.videoList = this.videoList.concat(videoList);
          let vid = this.$route.query.vid;
          //第一次加载
          if (this.isFirst) {
            let fIndex = this.videoList.findIndex((e) => e.id == vid);
            if (fIndex == -1) {
              this.getVidList();
              return;
            }
            this.isFirst = false;
            this.swiper.slideTo(fIndex, 0, true);
          }
        }
      } else {
        Toast(ret.msg);
      }
    },
    async getTagList() {
      let req = {
        pageNumber: String(this.pageNumber),
        pageSize: String(this.videoSize),
        newsType: 'SP', // 影视-MOVIE 图文-img 视频-SP 话题-tag
        tagID: this.$route.query.tagId,
      };
      let ret = await this.$Api(queryTagList, req);
      if (ret && ret.code === 200) {
        if (ret.data.list !== null) {
          let videoList = ret.data.list.filter((video) => video.newsType == 'SP');
          this.videoList = this.videoList.concat(videoList);
          let vid = this.$route.query.vid;
          //第一次加载
          if (this.isFirst) {
            let fIndex = this.videoList.findIndex((e) => e.id == vid);
            if (fIndex == -1) {
              this.getVidList();
              return;
            }
            this.isFirst = false;
            this.swiper.slideTo(fIndex, 0, true);
          }
        }
      } else {
        Toast(ret.msg);
      }
    },
    //获取视频
    async getVideo() {
      let fromPath = this.$route.query.fromRoutePath;
      if (fromPath == 'ComRecommend') {
        this.getCommunityVideo();
        return;
      }
      if (fromPath == 'SearchDetailsVideo') {
        this.getSearchVideo();
        return;
      }
      if (fromPath == 'CollecVideoList') {
        this.getCollecVideo();
        return;
      }
      if (fromPath == 'PurchasesVideoList') {
        this.getPurchasesVideo();
        return;
      }
      if (fromPath == 'UserHomePage') {
        this.getUserVideo();
        return;
      }
      if (fromPath == 'TagsVideo') {
        this.getTagList();
        return;
      }
      this.getVidList();
    },
  },
};
</script>
<style lang="scss" scoped>
.short_video {
  height: 100%;
  width: 100%;
  background: #000000;
  position: relative;
  /deep/ .xgplayer-controls {
    bottom: 20px !important;
  }
  /deep/ .left {
    bottom: 50px !important;
  }
  .back {
    position: absolute;
    top: 20px;
    left: 16px;
    z-index: 11;

    > svg {
      font-size: 18px;
    }
  }

  .bottom-all {
    height: 100%;
    position: relative;
  }

  .video-s {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;

    .video-swiper {
      height: 100%;
      width: 100%;

      .my_swiper_silde {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
